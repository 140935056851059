<template>
  <div class="card" style="min-height: 200px">
    <div class="table-responsive">
      <table class="table">
        <thead>
        <tr>
          <th>Date</th>
          <th>Bill Number</th>
          <th class="text-right">Bill Amount</th>
          <th class="text-right">Paid Amount</th>
          <th class="text-right">Due Amount</th>
          <th>Party Name</th>
          <th>Action</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, i) in purchase" :key="i">
          <td> {{ item.date }}</td>
          <td> {{ item.bill_number }}</td>
          <td class="text-right"> {{ item.bill_amount }}</td>
          <td class="text-right"> {{ item.paid_amount }}</td>
          <td class="text-right"> {{ item.due_amount }}</td>
          <td> {{ item.party_name }}</td>
          <td>
            <button class="btn btn-primary btn-sm"
                    @click="goToEdit(item.id)"
                    style="cursor: pointer"
            >
              <i class="fas fa-eye"></i>
            </button>
            <button class="btn btn-secondary btn-sm"
                    @click="goToPrint(item.id)"
                    style="cursor: pointer; margin-left: 10px"
            >
              <i class="fas fa-print"></i>
            </button>
          </td>
        </tr>

        </tbody>
      </table>
      <p v-if="!purchase.length" class="text-center mt-5">No data</p>
    </div>
  </div>
</template>

<script setup>
import {useRoute, useRouter} from "vue-router";

const props = defineProps({
  purchase: Array,
  route_name: {
    type: String,
    default: 'purchase-return-edit'
  },
})

const route = useRoute()
const router = useRouter()
const goToEdit = (id) => {
  let param = {
    companyId: route.params.companyId,
    moduleId: route.params.moduleId,
    menuId: route.params.menuId,
    pageId: route.params.pageId,
  }
  param['purchaseReturnId'] = id;
  router.push({
    name: props.route_name,
    params: param,
    query: route.query
  })
}
const goToPrint = (id) => {
  let param = {
    companyId: route.params.companyId,
    moduleId: route.params.moduleId,
    menuId: route.params.menuId,
    pageId: route.params.pageId,
  }
  param['purchaseReturnId'] = id;
  router.push({
    name: 'purchase-return-print',
    params: param,
    query: route.query
  })
}
</script>
